<template>
  <div>
    <div class="banner handshape" v-if="mediaNews.length > 0">
      <swiper :options="swiperOption" ref="videoSwiper" v-if="initOrNot && mediaNews.length > 1">
        <swiper-slide v-for="(item, index) in mediaNews" :key="index" @click="jumpBannerInfo(item)">
          <video v-if="item.is_video == 1" :src="item.image" :controls="false" muted @ended="endVideo(index)"
            class="multimedia" style="height: 100%"></video>
          <img @click="jumpBannerInfo(item)" v-else :src="item.image" class="multimedia" />
        </swiper-slide>
        <div style="height: 20px;" class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div v-for="(item, index) in mediaNews" :key="index" v-if="initOrNot && mediaNews.length < 2">
        <video v-if="item.is_video == 1" :src="item.image" :controls="false" muted @ended="endVideo(index)"
          class="multimedia" style="height: 100%"></video>
        <img :src="item.image" v-else class="multimedia" @click="jumpBannerInfo(item)" />
      </div>
    </div>
    <div class="outer_one screenWidth">
      <div class="outer_tip_1">We Provide Best of The Best Solutions</div>
      <div class="outer_tip_2">The company's main products: hydroxypropyl methyl cellulose (HPMC), dispersible emulsion
        powder (VAE) and polyvinyl alcohol powder (PVA).</div>
      <div class="outer_one_3">
        <div class="outer_one_3_item">
          <img :src="item.image" class="outer_one_3_item_img" v-for="(item, index) in info.image_list" :key="index"
            alt="">
        </div>
      </div>
    </div>
    <div class="outer_two screenWidth">
      <div class="outer_tip_1">We can provide for you</div>
      <div class="outer_tip_2">HPMC Chemical Cellulose for Construction and Detergents</div>
      <div class="outer_two_cont">
        <div @click="toproduct(item.id)" v-for="(item, index) in info.goods_cate" :key="index"
          :style="'background: linear-gradient(0deg,' + item.rgba2 + ' 0%, ' + item.background + ' 100%);padding: 1px;border-radius: 4px;'">
          <div style="background:#ffffff;border-radius: 4px;">
            <div class="outer_two_cont_item"
              :style="'background: linear-gradient(0deg,' + item.rgba1 + ' 1%,' + item.rgba2 + ' 99%;'">
              <div class="outer_two_cont_item_1" :style="'background: ' + item.background + ';'">
                <img src="@/assets/img/logo.png" alt="">
              </div>
              <div class="outer_two_cont_item_2" :style="'color: ' + item.background + ';'">{{ item.name }}</div>
              <div class="outer_two_cont_item_3">{{ item.descript }}</div>
              <div class="outer_two_cont_item_4">
                <img :src="item.image" class="outer_two_cont_item_4_1" alt="">
                <div class="outer_two_cont_item_4_2" :style="'color: ' + item.background + ';'">MORE</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="outer_three">
      <img src="@/assets/img/shouye_1.png" class="outer_three_bj" alt="">
      <div class="outer_three_cont screenWidth">
        <div class="outer_three_cont_item">
          <div @click="toproductdetail(item.id)" class="outer_three_cont_item_one"
            v-for="(item, index) in info.goods_list" :key="index">
            <img :src="item.image" class="outer_three_cont_item_one_1" alt="" mode="">
            <div class="outer_three_cont_item_one_2">{{ item.name }}</div>
          </div>
        </div>
        <div class="outer_three_cont_two handshape">
          <div @click="products">MORE</div>
        </div>
      </div>
    </div>
    <div class="outer_four screenWidth">
      <div class="outer_four_left">
        <div class="outer_four_left_item handshape" v-for="(item, index) in info.goods_image_list" :key="index"
          @click="enlargeTap(item)">
          <img :src="item.image" class="outer_four_left_item_img imgHoverBig" alt="">
          <div class="outer_four_left_item_one">{{ item.title }}</div>
        </div>
      </div>
      <div class="outer_four_right">
        <img src="@/assets/img/shouye_2.png" class="outer_four_right_bj" alt="">
        <div class="outer_four_right_cont" @click="isMore = true">
          <div class="outer_four_right_cont_1">Jiahong is a trusted manufacturer in China, providing samples and
            technical support free of charge.</div>
          <div class="outer_four_right_cont_2">Learn more</div>
        </div>
      </div>
    </div>
    <div class="outer_five screenWidth">
      <div class="outer_tip_1">Company honor</div>
      <div class="outer_tip_2">JIAHONG concentrates on every gram of HPMC</div>
      <div class="outer_five_cont">
        <!-- <img src="@/assets/img/jt_1.png" class="outer_five_cont_one" alt=""> -->
        <div class="outer_five_cont_two screenWidth">
          <vue-seamless-scroll :data="info.honor_list" :class-option="options" class="seamless-warp">
            <ul>
              <li v-for="item in info.honor_list" :key="item.index">
                <img :src="item.image" alt="" />
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
        <!-- <img src="@/assets/img/jt_2.png" class="outer_five_cont_one" alt=""> -->
      </div>
    </div>
    <div class="outer_six">
      <img src="@/assets/img/shouye_3.png" class="outer_six_bj" alt="">
      <div class="outer_six_cont screenWidth" v-if="rollShow">
        <div class="outer_six_cont_one">
          <div class="outer_six_cont_one_1">
            <countTo :duration="4000" :startVal="0" :endVal="Number(info.professional_team)"></countTo>
            <span>+</span>
          </div>
          <div class="outer_six_cont_one_2">Professional Team</div>
        </div>
        <div class="outer_six_cont_one">
          <div class="outer_six_cont_one_1">
            <countTo :duration="4000" :startVal="0" :endVal="Number(info.floor_space)"></countTo>
            <span>㎡</span>
          </div>
          <div class="outer_six_cont_one_2">Floor space</div>
        </div>
        <div class="outer_six_cont_one">
          <div class="outer_six_cont_one_1">
            <countTo :duration="4000" :startVal="0" :endVal="Number(info.factory_employee)"></countTo>
            <span>+</span>
          </div>
          <div class="outer_six_cont_one_2">Factory Employee</div>
        </div>
        <div class="outer_six_cont_one">
          <div class="outer_six_cont_one_1">
            <countTo :duration="4000" :startVal="0" :endVal="Number(info.partner_regions)"></countTo>
            <span>+</span>
          </div>
          <div class="outer_six_cont_one_2">Partner Regions</div>
        </div>
      </div>
    </div>
    <div class="outer_seven screenWidth">
      <div class="outer_tip_1">News center</div>
      <div class="outer_tip_2">Latest industry advice and news</div>
      <div class="outer_seven_cont">
        <div @click="tonewsDetail(item.id)" class="outer_seven_cont_item" v-for="(item, index) in info.news_list"
          :key="index">
          <img :src="item.image" class="outer_seven_cont_item_img" alt="">
          <div class="outer_seven_cont_item_2">{{ item.title }}</div>
          <div class="outer_seven_cont_item_3">{{ item.descript }}</div>
          <div class="outer_seven_cont_item_4">{{ item.createtime }}</div>
        </div>
      </div>
      <div @click="tonews" class="outer_seven_three handshape">
        <div>MORE</div>
      </div>
    </div>
    <!-- 放大图片 -->
    <div class="img_boxdg">
      <el-dialog :visible.sync="isEnlarge" top="7vh" :show-close="false" custom-class="enlargeBox screenWidth">
        <div class="enlarge_popup" :style="'max-height:' + enlargeHeight + 'px;height:' + enlargeHeight + 'px;'">
          <img :src="enlargeInfo.image" class="enlarge_popup_img" alt="">
          <div class="outer_four_left_item_one">
            <div>{{ enlargeInfo.title }}</div>
            <div class="outer_four_left_item_one_2">{{ enlargeInfo.descript }}</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="Contact Us" :visible.sync="isMore" custom-class="moreBox" :close-on-click-modal="false">
      <div class="more_popup">
        <div class="more_popup_1"><span>*</span>Name</div>
        <div class="more_popup_2">
          <input type="text" placeholder="Name" v-model="name" @blur="nameBlur" @input="nameBlur"
            :class="nameTip == true ? 'bjRed' : ''">
          <div class="errormessage" :style="'display: ' + (nameTip == true ? 'block' : 'none') + ';'">This field is
            required </div>
        </div>
        <div class="more_popup_1"><span>*</span>Email</div>
        <div class="more_popup_2">
          <input type="text" placeholder="Email" v-model="email" @blur="emailBlur" @input="emailBlur"
            :class="emailTip == true ? 'bjRed' : ''">
          <div class="errormessage" :style="'display: ' + (emailTip == true ? 'block' : 'none') + ';'">This field is
            required </div>
        </div>
        <div class="more_popup_1"><span>*</span>Tel/Whatsapp</div>
        <div class="more_popup_2">
          <input type="text" placeholder="Tel/Whatsapp" v-model="mobile" @blur="mobileBlur" @input="mobileBlur"
            :class="mobileTip == true ? 'bjRed' : ''">
          <div class="errormessage" :style="'display: ' + (mobileTip == true ? 'block' : 'none') + ';'">This field is
            required </div>
        </div>
        <div class="more_popup_1"><span>*</span>Message</div>
        <div class="more_popup_2">
          <textarea placeholder="Message" required="" v-model="message" @blur="messageBlur" @input="messageBlur"
            :class="messageTip == true ? 'bjRed' : ''"></textarea>
          <div class="errormessage" :style="'display: ' + (messageTip == true ? 'block' : 'none') + ';'">This field is
            required </div>
        </div>
      </div>
      <div slot="footer" class="el-dialog-footer">
        <div class="el-dialog-footer_1" @click="submit">Submit</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：首页
 * @author: 刘嘉鑫 
 * @version  V1.0 
 * @datecreate: 2024-07-18 09:13
 */
import "swiper/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import countTo from 'vue-count-to';
export default {
  components: {
    swiper,
    swiperSlide,
    countTo
  },
  data() {
    return {
      mediaNews: [],
      mediaLastIndex: 0,
      swiperOption: {
        //initialSlide: 0,
        loop: true,
        speed: 1000,
        autoplay: false,
        // grabCursor: true,
        setWrapperSize: true,
        autoHeight: true,
        uniqueNavElements: true,
        preventInteractionOnTransition: false,
        allowSilderPrev: true,
        allowSilderNext: true,
        //   scrollbar:'.swiper-scrollbar',//滚动条
        mousewheelControl: true,
        observer: true,
        observeParents: true,
        debugger: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          //控制第一个slide切换
          init: () => {
            // console.log("初始化")
            // this.initHandle();
          },
          slideChangeTransitionStart: () => {
            this.slideChangeTransitionStartHandle();
          },
          slideChangeTransitionEnd: () => {
            this.slideChangeTransitionEndHandle();
          },
        },
      },
      initOrNot: false, //mediaNews数据改变的时候确保swiper会init一次

      qualifications: [{}, {}, {}, {}, {}, {}],

      info: "",

      isEnlarge: false, // 放大图片
      enlargeInfo: "",
      enlargeHeight: "",

      isMore: false, // 了解更多
      name: "",
      nameTip: false,
      email: "",
      emailTip: false,
      mobile: "",
      mobileTip: false,
      message: "",
      messageTip: false,
      scrollDistance: 0,//当前滚动位置
      rollShow: false
    };
  },
  computed: {
    options() {
      return {
        // 滚动速度 , 数值越大滚动越快
        step: 0.8,
        // 滚动的数据条数
        limitMoveNum: 2,
        // 鼠标点击停止滚动, 默认true
        hoverStop: true,
        // 按住鼠标停止滚动, 默认true
        openTouch: true,
        // 滚动方向 , 0 向下 , 1 向上 , 2 向左 , 3 向右
        direction: 2,
        // 单步运动停止的高度(默认是0, 无缝不停止的滚动,direction是0或1)
        // 就是滚多高停一下,再滚多高又停一下,停的时间me就是waitTi
        // 这个高度,可以F12审查元素用箭头放在字上面看li的高度是多少
        singleHeight: 32,
        // 单步运动停止的宽度(默认是0, 无缝不停止的滚动,direction是2或3)
        singleWidth: 0,
        // 单步运动停止的时间 (默认值1000ms)
        waitTime: 0,
        // 开启数据实时监控刷新dom
        openWatch: true,


      };
    },
  },
  watch: {
    mediaNews: {
      handler(newName, oldName) {
        this.initOrNot = false;
        this.$nextTick(() => {
          this.initOrNot = true;
        });
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.getInfo()
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.enlargeHeight = window.innerHeight - 180
  },
  methods: {
    //计算滚动距离
    tonews() {
      this.$router.push({
        path: '/news',
      });
    },
    //计算滚动距离
    handleScrollbox() {
      if (this.screenWidth < 1024) return
      this.scrollDistance = window.pageYOffset;
      if (this.scrollDistance >= this.$(".outer_six ").offset().top - 930) {
        this.rollShow = true
      }
    },
    jumpBannerInfo(item) {
      console.log(item, '123123');
      if (item.jump_type == 1) {
        let contents = Buffer.from(item.content, 'utf8').toString('base64')
        this.$router.push({
          path: '/detail',
          query: { title: item.title, content: contents }
        });
      } else if (item.jump_type == 2) {
        this.$router.push({
          path: '/productDetails',
          query: { id: item.jump_id }
        });
      }
    },
    /**
     * 去新闻详情
     * 刘义博
     * 2024-7-22
     */
    tonewsDetail(id) {
      this.$router.push({
        path: '/newsDetail',
        query: { id: id }
      });
    },
    /**
     * 去产品详情
     * 刘义博
     * 2024-7-22
     */
    toproductdetail(id) {
      this.$router.push({
        path: '/productDetails',
        query: { id: id }
      });
    },
    /**
    * 去产品列表
    * 刘义博
    * 2024-7-22
    */
    toproduct(id) {
      this.$router.push({
        path: '/product',
        query: { id: id }
      });
    },
    /**
     * 详情
     * 刘嘉鑫
     * 2024-7-19
     */
    getInfo() {
      this.$request({
        url: "/Index/index",
      }).then((res) => {
        console.log("首页", res);
        res.data.goods_cate.map(item => {
          item.rgba1 = this.$util.hexToRgba(item.background, 0.2)
          item.rgba2 = this.$util.hexToRgba(item.background, 0)
        })
        this.info = res.data;
        this.mediaNews = res.data.adv_list
      });
    },

    /**
     * 轮播图
     * 刘嘉鑫
     * 2024-7-18
     */
    initHandle() {
      let that = this;
      // console.log(this.mediaNews)
      setTimeout(function () {
        let swiper = that.$refs.videoSwiper.swiper;
        console.log(that.mediaNews[0]?.is_video)
        if (that.mediaNews[0]?.is_video == 0) {
          that.mediaNewsImgHandle(swiper);
        } else {
          document
            .getElementsByClassName("multimedia")
          [swiper.activeIndex]?.play();
          // document.getElementsByClassName("multimedia")[0]?.play();
        }
      }, 200);
    },
    mediaNewsImgHandle(swiper) {
      console.log("切换")
      let that = this;
      //刚切换到的activeIndex
      let changePointActiveIndex = swiper.realIndex;
      if (swiper.realIndex < this.mediaNews.length - 1) {
        // console.log("swiper.realIndex < this.mediaNews.length - 1");
        setTimeout(function () {
          //要确认changePointActiveIndex是不是还是目前的activeIndex，是的话计时后执行，不是的话不执行
          if (changePointActiveIndex == swiper.realIndex) {
            swiper.slideNext();
          }
        }, 5000);
      } else {
        // console.log("swiper.realIndex > this.mediaNews.length - 1");
        setTimeout(function () {
          // console.log(
          //   "changePointActiveIndex == swiper.realIndex",
          //   changePointActiveIndex,swiper.realIndex
          // );
          // if (changePointActiveIndex == swiper.realIndex) {
          //   swiper.slideTo(0, 1000);
          // }
          swiper.slideNext();
        }, 5000);
      }
    },
    slideChangeTransitionStartHandle() {
      let that = this;
      setTimeout(function () {
        let swiper = that.$refs.videoSwiper.swiper;
        if (that.mediaNews[swiper.realIndex].is_video == 0) {
          document.getElementsByClassName("multimedia")[
            swiper.realIndex
          ].currentTime = 0;
        } else {
          // document
          //   .getElementsByClassName("multimedia")
          //   [swiper.activeIndex].play();
        }
      }, 200);
    },
    slideChangeTransitionEndHandle() {
      // is_video 0=图片，1=视频
      let that = this;
      console.log("end..");
      setTimeout(function () {
        let swiper = that.$refs.videoSwiper.swiper;
        console.log("swiper", swiper)
        console.log("[swiper.realIndex]", swiper.realIndex, that.mediaNews[swiper.realIndex])
        if (that.mediaNews[swiper.realIndex].is_video == 1) {
          console.log("是视频")
          console.log(document
            .getElementsByClassName("multimedia")
          )
          document
            .getElementsByClassName("multimedia")
          [swiper.activeIndex].play();
          document
            .getElementsByClassName("multimedia")
          [swiper.activeIndex].addEventListener(
            "ended",
            () => {
              //结束
              console.log("播放结束");
              // clearInterval(this.timer);
              that.endVideo(swiper.realIndex);
            },
            false
          );
        }
        if (that.mediaNews[swiper.realIndex].is_video == 0) {
          console.log("是图片")
          document
            .getElementsByClassName("multimedia")
          [that.mediaLastIndex].pause();
          that.mediaNewsImgHandle(swiper);

        } else {

          return
          document
            .getElementsByClassName("multimedia")
          [swiper.activeIndex].play();
          document
            .getElementsByClassName("multimedia")
          [swiper.activeIndex].addEventListener(
            "ended",
            () => {
              //结束
              console.log("播放结束");
              // clearInterval(this.timer);
              that.endVideo(swiper.realIndex);
            },
            false
          );
        }
        that.mediaLastIndex = swiper.realIndex;
      }, 200);
    },
    endVideo(index) {
      // console.log("endVideo", index);
      let swiper = this.$refs.videoSwiper.swiper;
      if (index === swiper.realIndex) {
        swiper.slideNext();
        if (this.mediaNews[swiper.realIndex].is_video == 1) {
          document
            .getElementsByClassName("multimedia")
          [swiper.activeIndex].play();
        } else {
          this.mediaNewsImgHandle(swiper);
        }
      }
    },

    /**
     * 更多商品
     * 刘嘉鑫
     * 2024-7-19
     */
    products() {
      this.$router.push({
        path: "/product",
      });
    },

    /**
     * 点击图片放大
     * 刘嘉鑫
     * 2024-7-22
     */
    enlargeTap(item) {
      this.isEnlarge = true
      this.enlargeInfo = item
    },

    /**
     * 提交反馈
     * 刘嘉鑫
     * 2024-7-20
     */
    submit() {
      if (this.name == '') {
        this.nameBlur()
        return
      } else if (this.email == '') {
        this.emailBlur()
        return
      } else if (this.mobile == '') {
        this.mobileBlur()
        return
      } else if (this.message == '') {
        this.messageBlur()
        return
      }
      this.$request({
        url: "/Index/feedBack",
        data: {
          name: this.name,
          email: this.email,
          mobile: this.mobile,
          message: this.message,
        }
      }).then((res) => {
        console.log("提交反馈", res);
        this.$message({
          message: res.msg,
          type: 'success',
          duration: 1500,
          offset: window.screen.height / 2.5,
        });
        this.name = ""
        this.email = ""
        this.emailTip = false
        this.mobile = ""
        this.mobileTip = false
        this.message = ""
        this.messageTip = false
        this.isMore = false
      }).catch(error => {
        this.$message({
          message: error.msg,
          type: 'none',
          duration: 2000,
          offset: window.screen.height / 2.5,
        });
      });
    },

    /**
     * 名字失去焦点
     * 刘嘉鑫
     * 2024-7-20
     */
    nameBlur() {
      if (this.name == '') {
        this.nameTip = true
      } else {
        this.nameTip = false
      }
    },
    emailBlur() {
      if (this.email == '') {
        this.emailTip = true
      } else {
        this.emailTip = false
      }
    },
    mobileBlur() {
      if (this.mobile == '') {
        this.mobileTip = true
      } else {
        this.mobileTip = false
      }
    },
    messageBlur() {
      if (this.message == '') {
        this.messageTip = true
      } else {
        this.messageTip = false
      }
    },
  },
};
</script>
<style scoped>
/* 轮播图 */
.swiper-pagination-bullets {
  margin-bottom: 6px !important;
}

.swiper-pagination {
  height: 20px !important;
  text-align: center;
  margin-top: -2.5%;
  z-index: 99;
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-pagination-bullet {
  display: inline-block !important;
  width: 55px !important;
  height: 2px !important;
  border-radius: 3px !important;
  background: #8c8e94 !important;
  margin: 0 10px !important;
}

.swiper-pagination-bullet-active {
  background: #0089F5 !important;
}

.enlargeBox .el-dialog__header {
  padding: 0 !important;
}

.el-dialog__title {
  color: #444;
  font-size: 16px;
  font-weight: bold;
}

.el-dialog {
  width: 32vw;
}

@media (min-width: 1024px) {
  /deep/ .el-dialog {
    width: 34vw !important;
  }
}

@media (min-width: 1px) and (max-width: 767px) {
  /deep/ .el-dialog {
    width: 90vw !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /deep/ .el-dialog {
    width: 80vw !important;
  }
}

.el-dialog__body {
  box-sizing: border-box;
  height: 100% !important;
  padding: 20px !important;
}

.img_boxdg .el-dialog {
  width: 32vw !important;
  height: 55vh !important;
  top: 40%;
  transform: translateY(-50%);
}

.img_boxdg .el-dialog__body {
  box-sizing: border-box;
  height: 100% !important;
  padding: 20px !important;
}
</style>
<style lang='less' scoped>
.banner {
  width: 100%;
  height: 722px;
  margin-bottom: 98px;
  overflow: hidden;

  img,
  .swiper-container,
  video,
  .swiper-slide {
    width: 100%;
    height: 100%;
  }
}


// 手机
@media (max-width: 768px) {

  .outer_tip_1 {
    font-size: 0.8em !important;
  }

  .outer_tip_2 {
    font-size: 0.4em !important;
  }

  .outer_one {
    .outer_one_3_item {

      .outer_one_3_item_img {
        width: 48.4% !important;
        height: 520px !important;
        margin-right: 50px !important;
        margin-bottom: 50px !important;
      }
    }

    .outer_one_3_item .outer_one_3_item_img:nth-child(2n) {
      margin-right: 0 !important;
    }
  }


  .outer_two {
    .outer_two_cont {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 0 !important;
    }

    .outer_two_cont_item {
      margin-bottom: 0.3em;
    }

    .outer_two_cont_item_1 {
      padding: 10px !important;

      img {
        width: 84px !important;
        height: 84px !important;
      }
    }

    .outer_two_cont_item_2 {
      font-size: 0.6em !important;
    }

    .outer_two_cont_item_3 {
      font-size: 0.4em !important;
    }

    .outer_two_cont_item_4_1 {
      width: 250px !important;
      height: auto !important;
    }

    .outer_two_cont_item_4_2 {
      font-size: 0.38em !important;
    }
  }

  .outer_three {

    .outer_three_cont {
      padding: 80px 0 !important;

      .outer_three_cont_item {
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 0.5em !important;
        margin-bottom: 60px !important;

        .outer_three_cont_item_one {

          .outer_three_cont_item_one_1 {
            max-height: 700px !important;
            background: #fff;
            object-fit: contain !important;
          }

          .outer_three_cont_item_one_2 {
            margin-top: 1em;
            font-size: 0.4em !important;
          }
        }
      }

      .outer_three_cont_two div {
        font-size: 0.4em !important;
      }
    }
  }

  .outer_four {
    align-items: center !important;
    flex-direction: column !important;

    .outer_four_left {
      width: 100% !important;

      .outer_four_left_item {
        width: 100% !important;
        height: 4em !important;
        margin-right: 0 !important;


      }
    }

    .outer_four_right {
      width: 1100px !important;
      height: 1700px !important;
      margin-left: 0 !important;

      .outer_four_right_cont {
        padding-top: 80px !important;
      }

      .outer_four_right_cont_1 {
        font-size: 52px !important;
        word-break: break-all !important;
        text-align: center !important;
      }

      .outer_four_right_cont_2 {
        font-size: 60px !important;
      }
    }
  }

  .outer_four_left_item_one {
    font-size: 52px !important;
    padding: 50px 40px !important;
  }

  .outer_five {
    .outer_five_cont_two {

      .seamless-warp {
        height: 4em !important;

        img {
          width: 6em !important;
          height: 4em !important;
        }
      }
    }
  }


  .outer_six {
    .outer_six_cont {
      flex-wrap: wrap !important;


      .outer_six_cont_one {
        width: 50% !important;
        margin-bottom: 40px !important;

        .outer_six_cont_one_1 {
          font-size: 0.8em !important;

          span {
            font-size: 0.7em !important;
          }
        }

        .outer_six_cont_one_2 {
          font-size: 0.5em !important;
        }
      }
    }
  }

  .outer_seven {
    .outer_seven_cont {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 40px !important;

      .outer_seven_cont_item_img {
        height: 8em !important;
      }

      .outer_seven_cont_item_2 {
        font-size: 80px !important;
      }

      .outer_seven_cont_item_3 {
        font-size: 70px !important;
      }

      .outer_seven_cont_item_4 {
        font-size: 60px !important;
      }
    }

    .outer_seven_three div {
      font-size: 60px !important;
    }
  }

  .more_popup_1 {
    font-size: 64px !important;
    margin-bottom: 20px !important;
  }

  .more_popup_2 {
    margin-bottom: 60px !important;

    input,
    textarea {
      font-size: 70px !important;
      padding: 40px !important;
    }

    .errormessage {
      font-size: 60px !important;
    }
  }

}


// 平板
@media (min-width: 768px) and (max-width: 1024px) {
  .outer_tip_1 {
    font-size: 72px !important;
  }

  .outer_tip_2 {
    font-size: 42px !important;
  }

  .outer_one_3_item {

    .outer_one_3_item_img {
      width: 48.4% !important;
      height: 360px !important;
      margin-right: 50px !important;
      margin-bottom: 50px !important;
    }
  }

  .outer_one_3_item .outer_one_3_item_img:nth-child(2n) {
    margin-right: 0 !important;
  }

  .outer_two {

    .outer_two_cont {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 0.6em !important;
    }

    .outer_two_cont_item_1 {
      padding: 10px !important;

      img {
        width: 84px !important;
        height: 84px !important;
      }
    }

    .outer_two_cont_item_2 {
      font-size: 1.3em !important;
    }

    .outer_two_cont_item_3 {
      font-size: 0.8em !important;
    }

    .outer_two_cont_item_4_1 {
      width: 410px !important;
      height: 312px !important;
      object-fit: contain !important;
    }

    .outer_two_cont_item_4_2 {
      font-size: 0.8em !important;
    }
  }

  .outer_three {

    .outer_three_cont {
      padding: 80px 0 !important;

      .outer_three_cont_item {
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 1.5em !important;
        justify-content: center;
        margin-bottom: 60px !important;

        .outer_three_cont_item_one {

          .outer_three_cont_item_one_1 {
            min-height:500px !important;
            object-fit: contain !important;
          }

          .outer_three_cont_item_one_2 {
            font-size: 1em !important;
          }
        }
      }

      .outer_three_cont_two div {
        font-size: 0.9em !important;
      }
    }
  }

  .outer_four {
    .outer_four_left_item {
      height: 8em !important;
    }

    .outer_four_left_item:nth-child(3),
    .outer_four_left_item:nth-child(4),
    .outer_four_left_item:nth-child(5) {
      width: 30.5% !important;
    }

    .outer_four_right {
      width: 11em !important;
      height: 16em !important;

      .outer_four_right_cont {
        padding-top: 40px !important;
      }

      .outer_four_right_cont_1 {
        font-size: 0.5em !important;
        word-break: break-all !important;
        text-align: center !important;
      }

      .outer_four_right_cont_2 {
        font-size: 0.5em !important;
      }
    }
  }

  .outer_four_left_item_one {
    font-size: 32px !important;
    padding: 20px !important;
  }


  .outer_five {
    .outer_five_cont_two {

      .seamless-warp {
        height: 8em !important;

        img {
          width: 10em !important;
          height: 8em !important;
        }
      }
    }
  }

  .outer_six {
    .outer_six_cont {
      flex-wrap: wrap !important;

      .outer_six_cont_one {
        width: 50% !important;
        margin-bottom: 40px !important;

        .outer_six_cont_one_1 {
          font-size: 1.2em !important;

          span {
            font-size: 0.9em !important;
          }
        }

        .outer_six_cont_one_2 {
          font-size: 0.8em !important;
        }
      }
    }
  }

  .outer_seven {
    .outer_seven_cont {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 40px !important;

      .outer_seven_cont_item_img {
        height: 8em !important;
      }

      .outer_seven_cont_item_2 {
        font-size: 0.9em !important;
      }

      .outer_seven_cont_item_3 {
        font-size: 0.7em !important;
      }

      .outer_seven_cont_item_4 {
        font-size: 0.7em !important;
      }
    }


    .outer_seven_three div {
      font-size: 0.9em !important;
    }
  }

  .more_popup_1 {
    font-size: 44px !important;
    margin-bottom: 20px !important;
  }

  .more_popup_2 {
    margin-bottom: 40px !important;

    input,
    textarea {
      font-size: 40px !important;
      padding: 40px !important;
    }

    .errormessage {
      font-size: 40px !important;
    }
  }


}

@media (min-width: 1024px) {

  .outer_one_3_item .outer_one_3_item_img:nth-child(6n + 3),
  .outer_one_3_item .outer_one_3_item_img:nth-child(6n + 4) {
    width: 48.8% !important;
  }

  .outer_one_3_item .outer_one_3_item_img:nth-child(3n) {
    margin-right: 0 !important;
  }

  .outer_four_left_item:nth-child(3),
  .outer_four_left_item:nth-child(4),
  .outer_four_left_item:nth-child(5) {
    width: 30.1% !important;
  }
}




.outer_tip_1 {
  text-align: center;
  font-weight: bold;
  font-size: 38px;
  color: #222222;
  margin-bottom: 18px;
}

.outer_tip_2 {
  font-weight: 400;
  font-size: 16px;
  color: #444444;
  text-align: center;
  margin-bottom: 60px;
}

.outer_one {
  margin-bottom: 69px;

  .outer_one_3 {
    .outer_one_3_item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .outer_one_3_item_img {
        width: 23.3%;
        height: 180px;
        margin-right: 27px;
        margin-bottom: 30px;
      }
    }
  }
}

.outer_two {
  margin-bottom: 58px;

  .outer_two_cont {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    align-items: center;

    .outer_two_cont_item {
      padding: 42px 42px 30px 33px;
      // background: linear-gradient(0deg, rgba(3, 158, 152, 0.2) 1%, rgba(3, 158, 152, 0) 99%);
      background: #ffffff;
      border-radius: 4px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      cursor: pointer;

      .outer_two_cont_item_1 {
        position: absolute;
        top: 0;
        left: 0;
        background: #039E98;
        border-radius: 4px 0px 4px 0px;
        padding: 8px;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .outer_two_cont_item_2 {
        font-weight: bold;
        font-size: 38px;
        color: #039E98;
        margin-bottom: 10px;
      }

      .outer_two_cont_item_3 {
        font-weight: 400;
        font-size: 16px;
        color: #222222;
        margin-bottom: 30px;
      }

      .outer_two_cont_item_4 {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .outer_two_cont_item_4_1 {
          width: 110px;
          height: 212px;
        }

        .outer_two_cont_item_4_2 {
          background: #FFFFFF;
          border-radius: 4px;
          padding: 12px 22px;
          font-weight: bold;
          font-size: 16px;
          color: #039E98;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.outer_three {
  position: relative;

  .outer_three_bj {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .outer_three_cont {
    padding: 40px 0;
    position: relative;

    .outer_three_cont_item {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(4, 1fr);
      gap: 25px;
      margin-bottom: 40px;

      .outer_three_cont_item_one:hover {
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }

      .outer_three_cont_item_one {
        width: 100%;
        border-radius: 4px;
        padding-bottom: 15px;

        .outer_three_cont_item_one_1 {
          width: 100%;
          max-height: 350px;
          background: #FFFFFF;
          border-radius: 4px;
          margin-bottom: 16px;
          padding: 30px;
          box-sizing: border-box;
        }

        .outer_three_cont_item_one_2 {
          font-weight: bold;
          font-size: 15px;
          color: #222222;
          text-align: center;
        }
      }

      .outer_three_cont_item_one:last-child {
        margin-right: 0 !important;
      }
    }

    .outer_three_cont_two {
      display: flex;
      justify-content: center;

      div {
        background: #BA0003;
        border-radius: 4px;
        padding: 12px 22px;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
      }
    }
  }
}

.outer_four {
  display: flex;
  align-items: flex-end;
  margin-top: 100px;
  margin-bottom: 95px;

  .outer_four_left {
    width: 60%;
    display: flex;
    flex-wrap: wrap;

    .outer_four_left_item {
      position: relative;
      width: 47.2%;
      height: 300px;
      border-radius: 4px;
      margin-right: 30px;
      margin-bottom: 30px;
      overflow: hidden;

      .outer_four_left_item_img {
        width: 100%;
        height: 100%;
      }
    }

    .outer_four_left_item:nth-child(2),
    .outer_four_left_item:nth-child(5) {
      margin-right: 0 !important;
    }
  }

  .outer_four_right {
    width: 409px;
    height: 606px;
    position: relative;
    margin-left: auto;
    margin-right: 20px;

    .outer_four_right_bj {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill !important;
    }

    .outer_four_right_cont {
      position: relative;
      padding: 22px 52px 20px 65px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .outer_four_right_cont_1 {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      .outer_four_right_cont_2 {
        background: #FFFFFF;
        border-radius: 4px;
        padding: 12px 12px;
        font-weight: 400;
        font-size: 16px;
        color: #BA0003;
        cursor: pointer;
      }
    }
  }
}

.outer_five {
  margin-bottom: 110px;

  .outer_five_cont {
    display: flex;
    align-items: center;
    justify-content: center;

    .outer_five_cont_one {
      width: 32px;
      height: 32px;
    }

    .outer_five_cont_two {

      .seamless-warp {
        height: 220px;
        overflow: hidden;

        ul {
          display: flex;
          align-items: center;
          margin: 0 !important;
          padding: 0 !important;

          li {
            list-style: none;

            img {
              width: 324px;
              height: 220px;
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
}

.outer_six {
  position: relative;
  margin-bottom: 102px;

  .outer_six_bj {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .outer_six_cont {
    position: relative;
    display: flex;
    align-items: center;
    padding: 60px 0;

    .outer_six_cont_one {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .outer_six_cont_one_1 {
        span:nth-child(1) {
          font-weight: bold;
          font-size: 42px;
          color: #FFFFFF;
        }

        span {
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          margin-left: -5px;
        }
      }

      .outer_six_cont_one_2 {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        margin-top: 32px;
      }
    }

  }
}

.outer_seven {
  margin-bottom: 100px;

  .outer_seven_cont {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    align-items: flex-start;
    margin-bottom: 20px;

    .outer_seven_cont_item:hover {
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    }

    .outer_seven_cont_item {
      cursor: pointer;
      padding: 10px;
      box-sizing: border-box;

      .outer_seven_cont_item_img {
        width: 100%;
        height: 260px;
        margin-bottom: 10px;
      }

      .outer_seven_cont_item_2 {
        font-weight: bold;
        font-size: 18px;
        color: #222222;
        margin-bottom: 10px;
      }

      .outer_seven_cont_item_3 {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-bottom: 28px;
      }

      .outer_seven_cont_item_4 {
        font-weight: 400;
        font-size: 16px;
        color: #999999;
      }
    }

    .outer_seven_cont_item:last-child {
      margin-right: 0 !important;
    }
  }

  .outer_seven_three {
    display: flex;
    justify-content: center;

    div {
      background: #BA0003;
      border-radius: 4px;
      padding: 12px 22px;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
    }
  }
}

.enlarge_popup {
  position: relative;
  height: 100% !important;

  .enlarge_popup_img {
    width: 100%;
    height: 100%;
  }
}


.outer_four_left_item_one {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.01) 100%);
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;

  .outer_four_left_item_one_2 {
    margin-top: 20px;
    font-weight: 400;
  }
}

.moreBox {
  .more_popup {
    .more_popup_1 {
      font-size: 14px;
      color: #1c1c1c;
      font-weight: 400;
      margin-bottom: 10px;

      span {
        color: #BA0003;
      }
    }

    .more_popup_2 {
      width: 100%;
      margin-bottom: 20px;

      input,
      textarea {
        width: 96%;
        padding: 10px;
        font-weight: 400;
        font-size: 12px;
        color: #222222;
        box-shadow: none;
        border-radius: 4px;
        outline: 0;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1) inset;
      }

      .bjRed {
        background: #ffece8 !important;
      }

      .errormessage {
        font-weight: 400;
        font-size: 14px;
        color: #BA0003;
        clear: both;
        display: none;
        margin-top: 5px;
      }
    }
  }


  .el-dialog-footer {
    display: flex;
    justify-content: flex-end;

    .el-dialog-footer_1 {
      border-width: 0 !important;
      border-radius: 2px;
      color: #fff;
      padding: 10px 14px;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      background: #BA0003;
    }
  }
}
</style>